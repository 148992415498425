import React from "react"
import ContactForm from "../../components/nico/ContactForm"
import ContactOther from "../../components/nico/ContactOther"
import HeadingL from "../../components/shared/HeadingL"
import Layout from "../../components/nico/Layout"
import SEO from "../../components/Seo"
import { PAGE } from "../../constants"

const contact = () => {
  return (
    <Layout>
      <SEO title={PAGE.title.contact} siteTitle="nico" />
      <HeadingL title={PAGE.title.contact} target="nico" />
      <ContactForm />
      <ContactOther />
    </Layout>
  )
}

export default contact
